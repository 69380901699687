<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title><a @click="goToList">{{ entity.title }}</a>&nbsp;-&nbsp;{{ product.title }}</v-card-title>
                <v-card-text>
                    <ProductTabLinks />
                    <v-row class="pl-3 pb-3" />
                    <v-form>
                        <v-row>
                            <v-col>
                                <v-select
                                    v-model="product.category_id"
                                    label="Категория"
                                    :items="categories"
                                    item-text="title"
                                    item-value="id"
                                    outlined
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-switch
                                    v-model="product.popular"
                                    label="Популярный"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="product.title"
                                    outlined
                                    label="Название"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="product.external_id"
                                    outlined
                                    label="Внешний ID"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    :value="formatedPrice"
                                    @input="setPrice"
                                    outlined
                                    label="Цена"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-img width="300px" v-if="product.picture" :src="imageSrc(product.picture)" class="mb-4" />
                                <v-file-input
                                    label="Картинка"
                                    outlined
                                    prepend-icon="mdi-image"
                                    hide-details
                                    @change="setPicture"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea
                                    v-model="product.description"
                                    outlined
                                    label="Описание"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="product.weight"
                                    outlined
                                    label="Вес (в граммах)"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea
                                    v-model="product.composition"
                                    outlined
                                    label="Состав"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="product.position"
                                    outlined
                                    label="Позиция"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-switch
                                    v-model="product.enabled"
                                    label="Активен"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-switch
                                    v-model="product.for_delivery"
                                    label="Показывать в разделе доставки"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-switch
                                    v-model="product.for_menu"
                                    label="Показывать в электронном меню"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn
                            color="primary"
                            class="mr-3"
                            @click="save"
                        >Сохранить</v-btn>
                        <v-btn
                            color="light"
                            class="mr-3"
                            @click="goBack"
                        >Отмена</v-btn>
                    </div>
                    <v-btn
                        v-if="isAdmin"
                        color="error"
                        @click="deleteModal = true"
                    >Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>

        <v-dialog
            v-model="deleteModal"
            width="800px"
        >
            <v-card>
                <v-card-title class="error">
                    Подтверждение
                </v-card-title>

                <v-card-text>
                    Вы действительно хотите удалить продукт? Это действие нельзя будет отменить.
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        color="error"
                        @click="remove"
                    >Удалить</v-btn>
                    <v-spacer />
                    <v-btn
                        color="light"
                        @click="deleteModal = false"
                    >Отмена</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import store from '../../store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';
    import ProductTabLinks from '../../components/sites/ProductTabLinks';

    import { imageSrc, priceFormated, translit } from '../../helpers';

    export default {
        name: 'ProductEdit',

        components: {
            ProductTabLinks,
        },

        data() {
            return {
                imageSrc,
                priceFormated,
                product: {
                    site_id: null,
                    category_id: null,
                    picture: null,
                    enabled: true,
                    for_delivery: true,
                    for_menu: true,
                    position: 0,
                },
                deleteModal: false,
                formatedPrice: 0
            };
        },

        computed: {
            ...mapState('auth', ['user']),
            ...mapState('siteTypes', {
                siteTypes: state => state.entities
            }),
            ...mapState('sites', {
                entity: state => state.entity
            }),
            ...mapState('categories', {
                categories: state => state.entities
            }),
            ...mapState('products', {
                oldProduct: state => state.entity
            }),
            isAdmin() {
                return this.user.admin;
            },
        },

        async mounted() {
            const { site_id, id } = this.$route.params;
            await store.dispatch('siteTypes/fetch');
            if(site_id) {
                await store.dispatch('sites/get', { id: site_id });
                await store.dispatch('categories/fetch', { site_id });
                this.product.site_id = site_id;
            }
            if(site_id && id) {
                await store.dispatch('products/get', { site_id, id });
                this.product = this.oldProduct;
                this.formatedPrice = this.oldProduct.price / 100;
            }
        },

        methods: {
            async setPicture(file) {
                this.product.picture = await store.dispatch('files/upload', { file });
            },
            setPrice(price) {
                this.formatedPrice = price;
                this.product.price = price * 100; 
            },

            async save() {
                try {
                    store.commit('products/SET_ENTITY', this.product);
                    const product = await store.dispatch('products/save', { site_id: this.$route.params.site_id });
                    const { site_id } = this.$route.params;
                    this.$router.push(`/sites/${site_id}/products/${product.id}/detail`);
                } catch (error) {
                    alert('Ошибка сохранения');
                }
            },

            async remove() {
                const { site_id, id } = this.$route.params;
                try {
                    await store.dispatch('products/delete', { site_id, id });
                    this.goToList();
                } catch (error) {
                    alert('Ошибка удаления');
                }
            },
            goToList() {
                const { site_id } = this.$route.params;
                if(site_id) {
                    this.$router.push(`/sites/${site_id}/products`);
                } else {
                    this.$router.push('/sites');
                }
            },
            goBack() {
                const { site_id, id } = this.$route.params;
                if(site_id && id) {
                    this.$router.push(`/sites/${site_id}/products/${id}/detail`);
                } else if(site_id) {
                    this.$router.push(`/sites/${site_id}/products`);
                } else {
                    this.$router.push('/sites');
                }
            },
        },
    };
</script>
